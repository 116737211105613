import React, { useState, useEffect } from "react";

// styles
import styles from "./header.module.css";
//to get website url
import { useLocation } from "react-router-dom";
//logo
import logo from "../../Assets/ovum-logo.png";
// import { FaUserDoctor } from "react-icons/fa";
//icons
import { FaHospital } from "react-icons/fa";
import { TbStethoscope } from "react-icons/tb";
import { SlCalender } from "react-icons/sl";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillLinkedin,
} from "react-icons/ai";

//link
import { Link } from "react-router-dom";
import { SidebarData } from "../../Assets/Data/Header/Sidebar";
import Sidebar from "./Sidebar";

//centers data

import CentersData from "../../Assets/Data/Centers/centers.json";

//speciality data
import specialityData from "../../Assets/Data/Specialities/speciality.json";

export default function Index() {
  const [sidebar, setSidebar] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);

  const [branchesHover, setBranchesHover] = useState(false);
  const [specialityHover, setSpecialityHover] = useState(false);
  const [doctorsHover, setDoctorsHover] = useState(false);
  let location = useLocation();

  const sidebarChange = () => {
    setSidebar(!sidebar);
  };

  const lowerCase = (value) => {
    return value.toLowerCase().split(" ").join("-");
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setSidebar(false);

    setBranchesHover(false);
    setSpecialityHover(false);
    setDoctorsHover(false);
  }, [location.pathname]);

  let stickyClasses = "";

  if (scrollPosition > window.innerHeight - 150) {
    stickyClasses = styles.headerMainSticky;
  }

  return (
    <>
      <section className={styles.menuTopBar}>
        <article> </article>
        <article>
          {/* <ul>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul> */}
        </article>
        <article className={styles.menuTopSocial}>
          <ul>
            <li>
              <a href="https://www.facebook.com/ovumhospitals">
                <AiFillFacebook size={20} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/ovum_hospitals/">
                <AiFillInstagram size={20} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/ovum-hospitals---india/">
                <AiFillLinkedin size={20} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/OVUMHospitals">
                <AiFillTwitterCircle size={20} />
              </a>
            </li>
          </ul>
        </article>
      </section>
      {/* className={styles.headerMainSticky } */}
      <section className={`${styles.headerNormalClass} ${stickyClasses}`}>
        <section className={styles.headerMaincontainer}>
          <article className={styles.headerLogo}>
            <Link to="/">
              <img
                src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/OVUM-LOGO-website%20(1).png?updatedAt=1705732823811"
                alt="Ovum Hospitals - Logo"
              />
            </Link>
          </article>
          <article className={styles.headerAlphaNavItems} id="menuWrapp">
            <section className={styles.headerNavItems}>
              <ul>
                <li
                  className={branchesHover && styles.showSubMenuList}
                  onMouseEnter={() => setBranchesHover(true)}
                  onMouseLeave={() => setBranchesHover(false)}
                >
                  <FaHospital className={styles.headerNavIcon} /> Our Branches
                  <MdOutlineArrowDropDown />
                  <ul className={styles.headerSubNavItems}>
                    {CentersData.map((item) => {
                      const { id } = item;
                      {
                        /* <Link to={`/centers/${lowerCase(centerName)}/${id}`}></Link> */
                      }
                      return (
                        <li key={id}>
                          <Link to={`/centers/${item.centerLink}`}>
                            {item.centerName}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li
                  className={specialityHover && styles.showSubMenuList}
                  onMouseEnter={() => setSpecialityHover(true)}
                  onMouseLeave={() => setSpecialityHover(false)}
                >
                  <TbStethoscope className={styles.headerNavIcon} /> Our
                  Specialities <MdOutlineArrowDropDown />
                  <ul className={styles.headerSubNavItems}>
                    {specialityData.map((item) => {
                      return (
                        <li key={item.id}>
                          {/* to={`/our-specialities/${item.specialityLink}/${item.id}`} */}
                          <Link to={`/our-specialities/${item.specialityLink}`}>
                            {item.specialityTitle}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li
                  className={doctorsHover && styles.showSubMenuList}
                  onMouseEnter={() => setDoctorsHover(true)}
                  onMouseLeave={() => setDoctorsHover(false)}
                >
                  <img
                    src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/doctor-man-profile-svgrepo-com%20(2).svg?updatedAt=1705742126115"
                    className={styles.headerNavIcon}
                  />
                  {/* <SlCalender className={styles.headerNavIcon} />  */}
                  Our Doctors
                  <MdOutlineArrowDropDown />
                  <ul className={styles.headerSubNavItems}>
                    {CentersData.map((item) => {
                      const { centerName, id, centerLink } = item;

                      return (
                        <li key={id}>
                          <Link to={`/doctors/${centerLink}/`}>
                            {item.centerName}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </section>
          </article>

          <article className={styles.headerSidebarContainer}>
            <div className={styles.headerSidebarImg}>
              <img
                src="https://ik.imagekit.io/d7t01fhx2/Ovum_Hospitals/Header/nabh-logo.png?updatedAt=1679574879231"
                alt="NABH Logo - Ovum Hospitals"
                id="imgClick"
                onClick={() => console.log("clicked on image")}
              />
            </div>
            <div className={styles.headerSidebarMenu}>
              <div>
                <GiHamburgerMenu onClick={sidebarChange} />
              </div>
            </div>
            <section
              className={`${styles.headerSidebar} ${
                sidebar ? styles.headerSidebarActive : styles.headerSidebar
              }`}
            >
              <article className={styles.SidebarContent}>
                <div>
                  {SidebarData.map((item, index) => {
                    return <Sidebar key={index} {...item} />;
                  })}
                </div>
                <div>
                  <AiFillCloseCircle
                    className={styles.sidebarClose}
                    onClick={() => sidebarChange()}
                  />
                </div>
              </article>
            </section>
          </article>
        </section>
      </section>
    </>
  );
}
